@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

.contlogin{
    display: flex;
    flex-direction: column;
    margin: auto;
    width:600px;
    margin-top: 200px;
    background: #fff;
    padding-bottom: 30px;

}

.header{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 9px;
    width: 100%;
    margin-top: 30px;
}

.text{
    color: #000000;
    font-size: 48px;
    font-weight: 500;
}

.underline{
    width: 61px;
    height:6px;
    background: #040404;
    border-radius: 9x;
}

.inputs{
    margin-top: 55px;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.input{
    display:flex;
    align-items: center;
    margin:auto;
    width:480px;
    height: 80px;
    background: #eaeaea;
    border-radius: 6px;
}

.input img{
    margin: 0px 30px;
}

.input input{
    height: 50px;
    width: 400px;
    background: transparent;
    border: none;
    outline:none;
    color: #797979;
    font-size: 19px;
}

.submit-container{
    display: flex;
    gap: 30px;
    margin: 60px auto;
}

.submit{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 210px;
    height: 52px;
    color: #fff;
    background: #000000;
    border-radius: 50px;
    font-size: 19px;
    font-weight: 600;
    cursor:pointer;
}