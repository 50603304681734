body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  background-color: #ffffff;
  text-align: left;
}

.app1{

  justify-content: space-between;
  display: inline-flex;
}
.title1{ 
  font-size: 22px;
  font-weight: 600;
  border-radius: '100px';
  text-align: left;
}

.description1{
  font-size: 22px;
  font-weight: 600;
}
.form.title.input1{
  min-height: 2000px;
}

.video-player{
  text-align: center;
  margin-left: 100px;
  margin-right: 10%;
  margin-top: 8%;
}
.form1{
  text-align:left;
  margin-top: 5%;
  margin-left: 10%;
  margin-bottom: 5%;
}

.dragbutton{
  display: flex;
  width: 157px;
  height: 39px;
  color: #fff;
  background: #000000;
  border-radius: 50px;
  font-size: 19px;
  font-weight: 600;
  cursor:pointer;
  
}
.checkbox{
  margin-right: 15px;
  margin-bottom: 10px;
}
.category{
  margin-right: 15px;
}

.dragbutton{
  margin-top: 5px;
  margin-top: 30px;
  margin-left: 550px;
}
.sub-filter{
  margin-top: 20px;
}
.video{
  object-fit: cover;
}
