@import url('https://fonts.googleapis.com/css?family=Lato&display=swap');

* {
    box-sizing: border-box;
}

body {
  font-family: 'Lato', sans-serif;
  background-color: white, #f7f7f7;
}
h1, h2, h3, h4, h5, h6, p{
  letter-spacing: 1px;
  text-align: left;
}
.cont{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
  margin-top: 4%;
}

/* FILE UPLOAD */
.drag-drop{
  align-self: lrft;
}

.dropzone {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
  margin-top: -4px;
  height: 50%;
  width: 60%;
  border: 4px dashed rgb(117, 112, 112);
  padding: 2rem;
}

.active {
  background-color: #5f5959;
}

button {
  width: 180px;
  height: 0%;
  background: rgb(0, 0, 0);
  border: 0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: medium;
  color:#f7f7f7;
  padding: 1rem;
  margin: 1rem;
  font-size: medium;
}


.list-item {
  display: flex;
  padding: 0;
  margin: 8px 0;
  min-width: 300px;
  font-size: 22px;
  background-color: #d4d2d2;
  cursor: grab;
  border-radius: 10px;
}

.list-item > h3 {
  padding: 10px;
  margin: 0;
}

.list-item > span {
  margin-right: 10px;
  font-size: 24px;
  font-weight: bold;
  border-right: 2px solid white;
  padding: 10px;
  padding-right: 16px;
}

.next-position {
  border: 2px solid #34d44f;
  opacity: 0.2;
}

.dragbutton2{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 210px;
  height: 52px;
  color: #fff;
  background: #000000;
  border-radius: 50px;
  font-size: 19px;
  font-weight: 600;
  cursor:pointer;
}

.searchvideo{



  width: 210px;
  height: 52px;
  border-radius: 50px;
  font-size: 19px;
  font-weight: 600;
  cursor:pointer;
}