@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  background-color: #ffffff;
}

.container {
  width: 100%;
  min-width: 1050px;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  align-items: center;
  height: 100%;
}

.navbar {
  height: 100%;
  background-color: #000000;
  display: flex;
}
.title{
  color: white;
  display: flex;
  justify-content: flex-start;
  margin-right: 50%;
}


