@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  background-color: #ffffff;
}

.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  align-items: center;
  height: 100%
}

.navbar {
  height: 100%;
  background-color: #000000;
  display: flex;
}
.title{
  color: white;

}


.nav-elements {
}

.nav-elements ul {
  display: flex;
  list-style-type: none;
  justify-content: flex-end;
}

.nav-elements ul li {
 
  margin-left: 60%;
}

.nav-elements ul a {
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  text-decoration: none;
}

.nav-elements ul a.active {
  color: #ffffff;
  font-weight: 500;
  position: relative;
  background-color: transparent;
}

.nav-elements ul a.active::after {
  content: "";
  position: absolute;
  bottom: -7px;
  left: 0;
  width: 100%;
  height: 25%;
  background-color: #ffffff;
}



