

.card{
  
    height: 40px;
    width: 650px;
    margin-top: 2px;
}
.cardimg{
    height: 140px;
    width: 230px;
}
.titleover{
}


.cardtitle{
    display: flex;
    justify-content: flex-start;
    margin-top: -1%;
 }

 .cardedit{
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: flex-start;
    margin-left: 83%;
    width: 110px;
    height: 30px;
    margin-top: -58px;
    background-color: rgb(37, 0, 223);

 }

 .carddel{
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: flex-start;
    margin-left: 90%;
    width: 70px;
    height: 30px;
    margin-top: -98px;
    background-color: red;
 }